import * as React from 'react';
import { SFC } from 'react';
import { Helmet } from 'react-helmet';
import { ContentfulEventPreviewFragment, ContentfulPageArticlePreviewFragment, PageArticleQuery_contentfulPageArticle } from '../gatsby-queries';
import { Globals } from '../utils/Globals';
import SEO from '../components/SEO/SEO';

export interface IPathContext {
	alternateLanguage: string | null;
	id: string;
	languageCode: 'en-US' | 'ar-QA';
	currSlug: string;
	upcomingEvents?: ContentfulEventPreviewFragment[];
	upcomingPageEvents?: ContentfulEventPreviewFragment[];
	latestPageArticles?: ContentfulPageArticlePreviewFragment[];
	latestArticles?: ContentfulPageArticlePreviewFragment[];
	articles?: {preview: ContentfulPageArticlePreviewFragment, node:PageArticleQuery_contentfulPageArticle}[];
	bilingual?: boolean;
	expertsPage?: boolean;
	newsPage?: boolean;
}

export interface IPageProps {
	pathContext: IPathContext;
	pageData: any;
	type: 'home' | 'article' | 'event' | 'press_release' | 'campaign' | 'overview' | 'media_gallery' | 'persona' | 'entity' | 'place' | 'program' | 'search' | 'vertical';
	title: string;
	location: any;
	metaTitle?: string;
	bilingual?: boolean;
}

const PageWrapper: SFC<IPageProps> = ({ children, pageData, type, pathContext, title, bilingual, metaTitle }) => {
	const languageAlternatives =
		pathContext.alternateLanguage !== null ? <link rel="alternate" href={`${Globals.BASE_URL}${pathContext.alternateLanguage}`} hrefLang={pathContext.languageCode === 'en-US' ? 'ar' : 'en'} /> : null;

	const languageCode = pathContext.languageCode === 'en-US' ? 'en' : 'ar';
	pathContext.bilingual = bilingual;
	let additionalTitle = '';
	const homeURL = ['/', '/ar']
	if(!homeURL.includes(pathContext.currSlug)) {
		additionalTitle = `| ${pathContext.languageCode === 'en-US' ? 'Qatar Foundation' : 'مؤسسة قطر'}`
	}
	return (
		<>
			{pageData && <SEO data={pageData} page={type} lang={pathContext.languageCode} />}
			<Helmet>
				<title>{title === 'Qatar Foundation' || title === 'مؤسسة قطر' ? title : `${metaTitle ? metaTitle : title} ${additionalTitle}`}</title>
				{!pageData && (
					<meta name="description" content="Qatar Foundation (QF) is a non-profit organization made up of more than 50 entities working in education, research, and community development." />
				)}
				<meta className="swiftype" name="title" data-type="string" content={title} />
				<meta className="swiftype" name="language" data-type="enum" content={languageCode} />
				<link rel="alternate" href={`${Globals.BASE_URL}${pathContext.currSlug === '/' ? '' : pathContext.currSlug}`} hrefLang={languageCode} />
				{languageAlternatives && languageAlternatives}
				<link rel="alternate" href={`${Globals.BASE_URL}${pathContext.languageCode === 'en-US' ? pathContext.currSlug === '/' ? '' : pathContext.currSlug : pathContext.alternateLanguage}`} hrefLang="x-default" />
			</Helmet>
			{children}
		</>
	);
};

export default PageWrapper;
